<template>
  <tooltip-icon :icon="icon" :color="iconColor" :text="text" large />
</template>

<script>
export default {
  name: "IconStatus",
  props: {
    status: {
      type: [Number, String],
      default: 1,
    },
    text: {
      type: String,
      default: "",
    },
  },
  computed: {
    icon() {
      const mapConditions = new Map([
        [1, "hourglass_empty"],
        [2, "check_circle_outline"],
        [3, "done_all"],
        [4, "cancel"],
      ]);
      return mapConditions.get(this.status);
    },
    iconColor() {
      const mapConditions = new Map([
        [1, "warning"],
        [2, "success"],
        [3, "blue darken-2"],
        [4, "error"],
      ]);
      return mapConditions.get(this.status);
    },
  },
};
</script>

<style scoped></style>
